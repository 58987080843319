//
// Dropzone
//

.dzu-submitButtonContainer {
  display: none;
}

.dzu-previewContainer {
  border-bottom: 1px solid #e4e6ef;
}

.dzu-previewFileName {

}

.dzu-previewButton {
  background-size: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  width: 2rem;
  height: 2rem;
}

// Base
.dzu-dropzone {
  border: 2px solid #e4e6ef;
}

.dzu-dropzone{

  .dzu-inputLabel {
    
    cursor: pointer;
  }
  
  .dzu-previewFileName {
    color:  $dark-75;
    margin: 0 0 5px 0;
    padding: 0;
    font-family: inherit;
    font-weight: 500;
    font-size: 1rem;
  }

  .dzu-dropzone-msg-desc {
    color:  $text-muted;
    font-weight: 400;
    font-size: 1rem;
  }

  .dz-preview {
    .dz-image {
      @include border-radius($border-radius);
    }
  }

  .dz-remove {
    color: $dark-50;
    font-size: 1px;
    font-weight: 500;
    transition: $transition-link;

    &:hover {
      transition: $transition-link;
      color: $primary;
    }
  }

  // State colors
  @each $name, $color in $theme-colors {
    &.dzu-dropzone-#{$name} {
      border-color: $color;
    }
  }
}

// Multipe upload
.dzu-dropzone.dzu-dropzone-multi {
  border: 0;
  padding: 0;

  .dz-message{
    display: none;
  }

  .dzu-dropzone-panel {
    .dzu-dropzone-upload,
    .dzu-dropzone-remove-all{
      display: none;
    }
  }

  .dzu-dropzone-item {
    display: flex;
    align-items: center;
    margin-top: 0.75rem;
    @include border-radius($border-radius);
    padding: 0.5rem 1rem;
    background-color: $gray-100;

    .dzu-dropzone-file {
      flex-grow: 1;

      .dzu-dropzone-filename {
        font-size: 0.9rem;
        font-weight: 500;
        color: $gray-600;
        text-overflow: ellipsis;
        margin-right: 0.5rem;

        b {
          font-size: 0.9rem;
          font-weight: 500;
          color: $text-muted;
        }
      }

      .dzu-dropzone-error {
        margin-top: 0.25rem;
        font-size: 0.9rem;
        font-weight: 400;
        color: $danger;
        text-overflow: ellipsis;
      }
    }

    .dzu-dropzone-progress {
      width: 15%;

      .progress {
        height: 5px;
        @include transition;
      }
    }

    .dzu-dropzone-toolbar {
      margin-left: 1rem;
      display: flex;
      flex-wrap: nowrap;

      .dzu-dropzone-start,
      .dzu-dropzone-cancel,
      .dzu-dropzone-delete {
        height: 25px;
        width: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: $transition-link;

        i {
          transition: $transition-link;
          font-size: 0.8rem;
          color: $gray-600;
        }

        &:hover {
          transition: $transition-link;
          i {
            color: $primary;
          }
        }
      }

      .dzu-dropzone-start{
        transition: $transition-link;
      }
    }
  }
}
